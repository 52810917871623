import React from "react"
import { Link } from "gatsby"

import { Container, Row, Col } from "reusecore/Layout"
import Box from "reusecore/Box"
import Badge from "reusecore/Badge"
import Heading from "reusecore/Heading"
import Text from "reusecore/Text"

import BannerImage from "assets/images/banners/banner-one/banner-one-thumb.svg"

import particleTopLeft from "assets/images/particles/banner/particle-top-left.png"
import particleUnderLogo from "assets/images/particles/banner/particle-under-logo.png"
import prticleTopRight from "assets/images/particles/banner/prticle-top-right.png"
import particleBottomLeft from "assets/images/particles/banner/particle-bottom-left.png"
import particleBottomRight from "assets/images/particles/banner/particle-bottom-right.png"

import BannerWrapper from "./banner.style"

const Banner = () => {
  return (
    <BannerWrapper id="home">
      <img
        src={particleTopLeft}
        className="section__particle top-left"
        alt="cryptik particles"
      />
      <img
        src={particleUnderLogo}
        className="section__particle two"
        alt="cryptik particles"
      />
      <img
        src={prticleTopRight}
        className="section__particle top-right"
        alt="cryptik particles"
      />
      <img
        src={particleBottomLeft}
        className="section__particle bottom-left"
        alt="cryptik particles"
      />
      <img
        src={particleBottomRight}
        className="section__particle bottom-right"
        alt="cryptik particles"
      />
      <Container>
        <Row>
          <Col className="lg-8 xs-12">
            <Box className="banner-content">
              <Heading as="h1">
              Welcome to the <br/> Potentiam Network
              </Heading>
              <Text>
              Redesigning the Music Landscape

              </Text>
              <Box className="banner-btn">
                <a href="https://wallet.potentiam.io" target="_blank" rel="noreferrer" className="btn btn-fill">
                  Join Now
                </a>
                <a href="whitepaper.pdf" target="_blank" className="btn whitePaper">
                  White Paper
                </a>
              </Box>
            </Box>
          </Col>
        </Row>
        {/* <img
          src={BannerImage}
          className="banner__thumb"
          alt="cryptik banner thumb"
        /> */}
      </Container>
    </BannerWrapper>
  )
}

export default Banner
